.grocery-cartitem {
    &__image-holder {
        margin: 0;
        width: 45px;

        img {
            width: 100%;
        }
    }

    &__nameText {
        font-size: 11px;
        text-transform: capitalize;
        font-weight: 500;
    }

    &__smallLightText {
        font-size: 10px;
        color: #999;
    }

    &__smallDarkText {
        font-size: 10px;
        color: #222;
    }

    &__countText {
        font-size: 10px;
        color: #999;
        border: 1px solid #999;
        border-radius: 2px;
        padding: 2px;
        text-align: end;
    }

    &__buttonLabel {
        color: red;
        font-size: 8px;
    }

    &__lastColumn {
        text-align: end;
    }
}
