$errorColor: #f44336;
$white: #fff9f8;
$buttonHover: gray;
$black: #000000;
$grayText: #61697d;

.root {
    height: 100vh;
}

.right-panel-container {
    overflow: auto;
    height: 100vh;
    background-color: $white;
    padding-top: 40px;

    &.signup-right {
        padding-left: 100px;
    }
}

.right-panel {
    max-width: 450px;
    text-align: center;
}

.logo {
    width: 84px;
}

.form {
    width: 100%; // Fix IE 11 issue.
}

.input {
    background-color: transparent;
}

.input-pin {
    font-size: 24px;
}

.error-message-container {
    text-align: center;
}

.error-message {
    color: $errorColor;
}

button {
    &.submit-btn {
        background: $black;
        border-radius: 20px;
        margin: 24px 0px 16px;
        padding-left: 40px;
        padding-right: 40px;

        &:hover {
            background-color: $buttonHover;
        }
    }
}

.bottom-links {
    text-align: left;
    margin-bottom: 35px;
}

.no-wrap {
    flex-shrink: 0;
    flex-wrap: nowrap;
    flex: 0 0 auto;
}

.header {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 120px;
}

.gray-text {
    color: $grayText;
}

.main-text {
    font-family: 'Dubai', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 32px;
    font-weight: bold;
    padding-top: 30px;
}

.explanation-text {
    margin-bottom: 15px;
}

.cane-front-panel-image {
    height: 250px;
    margin-bottom: 20px;
}

.terms-footer {
    margin-bottom: 30px;
    font-size: 14px;
}

.phone-number-input {
    background-color: transparent;
    border: none;
    font: inherit;

    &:focus {
        outline: none;
    }
}

.section-subtitle {
    &.MuiTypography-root {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 30px;
        margin-top: 10px;
    }
}
