@font-face {
    font-family: 'Circular-Medium';
    src: url('../../assets/fonts/lineto-circular-medium.eot');
    src: url('../../assets/fonts/lineto-circular-medium.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/lineto-circular-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Circular';
    src: url('../../assets/fonts/lineto-circular-bold.eot');
    src: url('../../assets/fonts/lineto-circular-bold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/lineto-circular-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Circular-Black';
    src: url('../../assets/fonts/lineto-circular-black.eot');
    src: url('../../assets/fonts/lineto-circular-black.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/lineto-circular-black.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Circular-Italic';
    src: url('../../assets/fonts/lineto-circular-boldItalic.eot');
    src: url('../../assets/fonts/lineto-circular-boldItalic.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/lineto-circular-boldItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Circular-Book';
    src: url('../../assets/fonts/lineto-circular-book.eot');
    src: url('../../assets/fonts/lineto-circular-book.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/lineto-circular-book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Circular';
    src: url('../../assets/fonts/lineto-circular-medium.eot');
    src: url('../../assets/fonts/lineto-circular-medium.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/lineto-circular-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Circular-Black';
    src: url('../../assets/fonts/lineto-circular-blackItalic.eot');
    src: url('../../assets/fonts/lineto-circular-blackItalic.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/lineto-circular-blackItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Circular-Book';
    src: url('../../assets/fonts/lineto-circular-bookItalic.eot');
    src: url('../../assets/fonts/lineto-circular-bookItalic.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/lineto-circular-bookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Akkurat-Bold';
    src: url('../../assets/fonts/akkurat-pro-bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Akkurat';
    src: url('../../assets/fonts/akkurat-pro-regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
