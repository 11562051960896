.sign-up {
    &__go-back-btn {
        &.MuiTypography-colorPrimary {
            color: #61697d;
            margin-top: 10px;
        }
        align-items: center;
        cursor: pointer;
        display: flex;
        font-size: 16px;
        font-weight: 500;

        &:hover {
            text-decoration: none;
        }
    }

    &__home-icon {
        &.MuiSvgIcon-root {
            float: right;
            font-size: 2.5rem;
        }
    }

    &__new-address-holder {
        &.MuiGrid-item {
            border-color: rgba(0, 0, 0, 0.1);
            border-top-style: solid;
            border-top-width: 1px;
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 0;
            margin-top: 36px;
            padding-top: 22px;
            padding: 0;

            &:first-of-type {
                border-top-width: 0;
                margin-top: 0;
            }

            &:last-of-type {
                margin-bottom: 40px;
            }
        }
    }

    &__add-more-btn {
        text-transform: none;
    }

    &__groceries-root {
        background-color: white;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        overflow: hidden;
    }

    &__groceries-grid-list {
        height: 450px;
        width: 500px;

        &__icon {
            color: rgba(255, 255, 255, 0.54);
        }
    }
}
