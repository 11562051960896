@import '../generals';

.login {
    &__checkbox-container {
        width: 100%;
    }

    &__remember-me {
        float: left;
        width: 100%;
    }
}
