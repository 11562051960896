.onboarding {
    background: #ffffff;

    @media (min-width: 600px) {
        width: 440px;
    }

    @media (max-width: 600px) {
        background-position: center top;
        background-size: contain;
        padding: 16px;
        width: 340px;
    }

    @media (max-width: 470px) {
        width: auto;
    }

    &__header {
        background-repeat: no-repeat;
        background-size: cover;
        background: #ffffff;
        background-position: top center;
        padding-top: 250px;
        width: 100%;
        position: relative;
    }

    &__top-container {
        &.MuiGrid-item {
            width: 100%;

            @media (max-width: 600px) {
                margin-top: 180px;
            }

            @media (max-width: 470px) {
                margin-top: 140px;
            }
        }
    }

    &__main-container {
        padding: 24px 32px 24px 32px;
    }

    &__main-text {
        font-family: 'Avenir', sans-serif;
        font-size: 24px;
        font-weight: 800;
        margin-bottom: 10px;
        text-align: center;

        @media (min-width: 470px) {
            width: 300px;
        }
    }

    &__explanation-text {
        font-family: 'Avenir', sans-serif;
        color: #61697d;
        font-size: 14px;
        margin-bottom: 10px;
        margin-top: 0;
        text-align: center;

        @media (min-width: 470px) {
            width: 320px;
        }
    }

    &__step-circle {
        border-radius: 50%;
        display: inline-block;
        height: 6px;
        margin-left: 6px;
        margin-right: 6px;
        width: 6px;

        &--active {
            background: #2f3c98;
        }
        &--light {
            background: #dde1ff;
        }
    }

    &__footer {
        margin-top: 20px;
        padding: 24px 32px 24px 32px;
    }

    &__cancel-button {
        color: #61697d;
    }
}
