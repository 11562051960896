// breakpoints
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1920px;

.full-width {
    width: 100%;
}

.form-group {
    margin-bottom: 16px;
}

// landing
.heading-2 {
    font-family: Sen, sans-serif;
    font-size: 20px;
    font-weight: 800;
    line-height: 1;
    padding: 20px 0;
    width: 80%;

    @media screen and (min-width: 991px) {
        font-size: 44px;
        font-weight: 700;
        padding: 0;
    }
}

.heading-3 {
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    font-weight: 800;
    margin: 0 0 30px 0;

    @media screen and (min-width: 991px) {
        font-size: 32px;
        font-weight: 700;
        margin: 0;
        padding: 0;
    }
}

.heading-4 {
    font-family: 'Circular', sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-align: center;

    @media screen and (min-width: 991px) {
        font-size: 32px;
        width: 100%;
    }
}

// mixins
@mixin dashboard-input {
    background-color: white;
    border-radius: 4px;
    border: 1px solid white;
    box-shadow: 0 0 7px #d7d7d7;
    box-sizing: border-box;
    color: #555;
    display: block;
    font-family: 'Avenir', sans-serif;
    font-size: 12px;
    height: 34px;
    line-height: 1.42857143;
    outline: none;
    padding: 6px 12px;
    width: 100%;
}

@mixin dashboard-btn {
    border-radius: 12px;
    padding: 5px 22px;
    border: 2px solid;
    color: #4051b6;
    font-size: 10px;
    font-weight: 500;
    font-family: 'Avenir', sans-serif;
    text-align: center;

    @media (min-width: $breakpoint-sm) {
        font-size: 13px;
    }

    &.Mui-disabled {
        color: rgba(0, 0, 0, 0.26);
    }

    &.filled {
        background-color: #4051b6;
        border-color: #4051b6;
        color: white;
    }

    &__icon {
        font-size: 1.2rem;
        margin-right: 13px;
    }
}

.dashboard-input {
    @include dashboard-input();
}

.dashboard-btn {
    &.MuiButtonBase-root {
        @include dashboard-btn;
    }
}

.error-message {
    color: red;
}
