.emergency-contact {
    background: #ffffff;
    width: 460px;
    padding: 24px 32px 24px 32px;

    &__title {
        font-size: 24px;
        font-weight: 800;
    }

    &__close-icon {
        cursor: pointer;
    }

    &__main-container {
        &.MuiGrid-item {
            margin-top: 65px;
            width: 100%;
        }
    }

    &__main-text {
        &.MuiGrid-item {
            font-size: 24px;
            font-weight: 800;
            margin-bottom: 8px;
            text-align: center;
        }
    }

    &__explanation-text {
        &.MuiGrid-item {
            width: 450px;
            font-size: 16px;
            text-align: center;
        }
    }

    &__save-button {
        &.MuiButton-root {
            margin-left: 8px;
        }
    }

    & footer {
        &.MuiGrid-item {
            margin-top: 40px;
        }
    }
}

.gray-text {
    color: #61697d;
}
