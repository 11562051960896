@import '../../../scss/variables/colors';
@import '../../../scss/general/general';

.can-footer {
    background-color: $black;
    padding: 48px 10px 20px;

    @media (min-width: $breakpoint-sm) {
        padding: 65px 30px 20px;
    }

    &__logo {
        margin-bottom: 30px;
        text-align: center;

        @media (min-width: $breakpoint-sm) {
            margin-bottom: 0px;
            text-align: left;
        }

        & a {
            color: $white;
            font-family: 'Circular-Book', sans-serif;
            font-weight: 300;
            text-decoration: none;

            & > span {
                font-weight: bold;
            }
        }
    }

    &__social-links {
        justify-content: center;
        margin: 0;

        @media (min-width: $breakpoint-sm) {
            justify-content: flex-end;
        }

        & > &__item {
            margin: 10px;
        }
    }

    &__bottom {
        color: #f9f9f9;
        margin-top: 55px;
        text-align: center;
    }
}
