@import '../../scss/general/general';

.dashboard-container {
    & .card {
        padding: 30px 0 0 0;

        &__content {
            & .module {
                padding: 0 0 80px;
                position: relative;
            }

            & .module-top {
                padding: 0 20px;
            }

            & .module-fields {
                box-sizing: border-box;
                max-height: 285px;
                min-height: 285px;
                overflow-x: hidden;
                overflow-y: scroll;
                padding: 10px 20px 0;
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */

                /* Hide scrollbar for Chrome, Safari and Opera */
                &::-webkit-scrollbar {
                    display: none;
                }

                &.overflow-unset {
                    overflow: unset;
                }

                & input {
                    @include dashboard-input();
                }

                & textarea {
                    @include dashboard-input();
                    height: auto;
                }

                & p {
                    font-family: 'Avenir', sans-serif;

                    &.confirmation-text {
                        margin: 30px 0;
                    }
                }

                &--left-addon {
                    padding-left: 20px;
                    position: relative;
                }

                &__ride-pickup,
                &__ride-dropoff {
                    &::after {
                        background-color: #121212;
                        content: '';
                        display: block;
                        height: 108%;
                        left: 5px;
                        position: absolute;
                        width: 1px;
                        transition: all 0.3s;
                    }
                    &::before {
                        background-color: #121212;
                        content: '';
                        display: block;
                        height: 12px;
                        left: 0;
                        position: absolute;
                        width: 12px;
                    }
                }

                &__ride-pickup {
                    &::after {
                        top: 22px;
                    }
                    &::before {
                        border-radius: 100%;
                        top: 10px;
                    }
                }

                &__ride-dropoff {
                    &::after {
                        bottom: 22px;
                    }
                    &::before {
                        bottom: 13px;
                    }
                }
            }

            & .module-bot {
                bottom: 20px;
                box-sizing: border-box;
                left: 0;
                padding: 0 20px;
                position: absolute;
                width: 100%;

                & p.module-bot-right-price {
                    color: #222;
                    font-family: 'Circular', sans-serif;
                    font-size: 21px;
                    font-weight: 500;
                    margin: 0;
                }

                & p.module-bot-right-taxestxt {
                    color: #8a909f;
                    font-size: 10px;
                    margin: 0;
                }

                & p {
                    font-family: 'Circular-Book', sans-serif;
                    font-size: 10px;
                    color: #8a909f;
                    margin: 0;
                    text-align: right;
                }
            }

            & .products-list {
                align-items: flex-start;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                max-height: 265px;
                min-height: 265px;
                overflow: scroll;
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */

                /* Hide scrollbar for Chrome, Safari and Opera */
                &::-webkit-scrollbar {
                    display: none;
                }

                & > .grocery-product {
                    background-color: #f6f6f6;
                    border-radius: 4px;
                    flex-basis: 46%;
                    margin: 4px;

                    @media (min-width: $breakpoint-sm) {
                        flex-basis: 30%;
                    }

                    & .productmodule-price {
                        color: #8a909f;
                        font-size: 11px;
                        font-weight: 500;
                        margin-left: 4px;
                        margin-top: 4px;
                        padding: 3px;
                    }

                    & img {
                        width: 75%;
                    }

                    & .hovereffect {
                        overflow: hidden;
                        position: relative;

                        &:hover > .overlay {
                            top: 0%;
                            transition: top 0.3s;
                        }

                        & .overlay {
                            background-color: transparentize(#000, 0.3);
                            border-radius: 4px;
                            color: #fff;
                            height: 100%;
                            padding: 25px 0 14px;
                            position: absolute;
                            top: 100%;
                            width: 100%;

                            & h6 {
                                margin: 10px 0;
                            }

                            & button.hovereffect-btadd {
                                background-color: unset;
                                border-radius: 50px;
                                border: 1px solid white;
                                color: white;
                                display: table;
                                font-size: 12px;
                                margin: 0 auto;
                                outline: none;
                                padding: 5px 8px;
                                text-transform: capitalize;
                            }

                            & .btn-group {
                                border-radius: 50px;
                                border: 1px solid white;
                                display: table;
                                margin: 0 auto;

                                & button {
                                    background: none;
                                    border: 0;
                                    box-sizing: border-box;
                                    color: white;
                                    font-size: 12px;
                                    outline: none;
                                    line-height: 1;
                                }
                            }

                            & .productmodule-price-wh {
                                font-size: 12px;
                                left: 4px;
                                position: absolute;
                                top: 4px;
                            }
                        }
                    }
                }
            }
        }
    }
}
