@import '../../../scss/general/general';

.can-header {
    box-sizing: border-box;
    padding: 10px;
    position: absolute;
    width: 100%;

    @media screen and (min-width: $breakpoint-sm) {
        padding: 30px;
    }

    &__navbar {
        &__logo {
            color: black;
            display: block;
            font-family: Sen, sans-serif;
            font-size: 22px;
            text-decoration: none;

            @media (min-width: $breakpoint-sm) {
                font-size: 30px;
            }

            & h4 {
                font-weight: 300;

                & span {
                    font-weight: bold;
                }
            }
        }

        &__navigation {
            & a.nav-btn {
                background-color: #050505;
                border-radius: 5px;
                color: #eeeeee;
                display: block;
                font-weight: 700;
                padding: 10px 6px;
                text-align: center;
                text-decoration: none;
                transition: all 0.3s;

                @media (min-width: $breakpoint-sm) {
                    // padding: 20px 16px;
                }

                & span {
                    display: block;
                    font-size: 18px;
                    margin-bottom: 4px;

                    @media (min-width: $breakpoint-sm) {
                        font-size: 24px;
                        // margin-bottom: 12px;
                    }

                    &.small {
                        font-size: 10px;
                    }
                }

                &:hover {
                    transform: scale(1.04);
                }
            }

            & span {
                font-size: 15px;
            }
        }
    }
}