@import '../../../scss/variables/colors';

.site-nav {
    position: relative;

    &__logo-holder {
        margin: 0 auto 30px;
        text-align: center;
        width: 40px;

        & > img {
            width: 100%;
        }
    }

    &__user-info {
        & > h5 {
            font-family: 'Circular', sans-serif;
            font-size: 14px;
            margin-bottom: 6px;
            margin-top: 0;
            padding-bottom: 0;
        }
        & > a {
            color: $gray;
            font-size: 12px;
            text-decoration: none;
        }
    }

    &__menu-container {
        margin-top: 25px;
        margin-bottom: 50px;
    }

    &__footer {
        bottom: 30px;
        max-width: 200px;
        padding: 0 10px;
        position: fixed;
        width: 100%;

        & a,
        & span {
            color: $black;
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            text-decoration: none;
        }
    }

    &__edit-icon {
        padding-right: 3px;
    }

    &__edit-container {
        margin: 25px 0;
        padding: 0 25px;
    }
}
