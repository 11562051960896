.AddedCount {
    &__btnAdded {
        background-color: #4051b6;
        border-color: #4051b6;
        border-radius: 60px;
        color: white;
        font-size: 10px;
        line-height: 1.8;
        width: 50px;
        text-align: center;
    }

    &__container {
        height: 20px;
        padding-left: 4px;
        padding-right: 4px;
        margin-bottom: 4px;
    }

    &__productCount {
        background-color: transparent;
        border-radius: 60px;
        border: 1px solid #8a909f;
        color: #8a909f;
        font-size: 10px;
        padding: 0 3px;
        text-align: right;
    }
}
