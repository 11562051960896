.user-info-container {
    background-color: white;
    border-right: 0px;
    box-shadow: 2px 0px 8px rgba(228, 236, 239, 0.53);
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 32px;

    @media (min-width: 1280px) {
        flex-basis: 300px;
    }

    @media (max-width: 960px) {
        padding-left: 32px;
        padding-right: 32px;
        padding-top: 40px;
    }

    @media (max-width: 600px) {
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 24px;
    }

    &__logo-container {
        height: 50px;
        width: 84px;

        @media (max-width: 470px) {
            width: 64px;
            height: 30px;
        }
    }

    &__logo {
        width: 100%;
    }

    &__user-image-container {
        height: 128px;
        margin-top: 40px;
        width: 128px;

        @media (max-width: 960px) {
            margin-top: 24px;
        }

        @media (max-width: 470px) {
            height: 100px;
            width: 100px;
        }
    }

    &__user-image {
        border-radius: 50%;
        height: 100%;
        width: 100%;
    }

    &__main-user-info {
        margin-top: 25px;
        text-align: center;

        @media (max-width: 960px) {
            margin-top: 16px;
        }

        @media (max-width: 600px) {
            margin-top: 8px;
        }
    }

    &__user-phonenumber {
        font-size: 18px;
        font-weight: bold;
    }

    &__user-email {
        font-size: 18px;
        margin-bottom: 16px;
        margin-top: 8px;

        @media (max-width: 600px) {
            font-size: 16px;
            margin-top: 4px;
        }
    }

    &__full-info-btn {
        color: #2f3c98;
        cursor: pointer;
        font-size: 16px;
        font-weight: 800;
        margin-bottom: 24px;

        @media (min-width: 1280px) {
            display: none;
        }
    }

    &__additional-user-info {
        margin-top: 40px;

        @media (max-width: 470px) {
            margin-top: 0px;
        }
    }

    &__additional-info-item {
        margin-top: 15px;
    }

    &__additional-info-key {
        font-size: 14px;
    }

    &__additional-info-key {
        font-size: 18px;
    }

    &__user-edit-container {
        margin-top: 40px;
        width: 100%;
    }

    &__edit-icon {
        height: 16px;
        padding-right: 3px;
        width: 16px;
    }

    &__user-info-footer {
        margin-bottom: 20px;
        margin-top: 100px;
        min-width: 275px;

        @media (max-width: 1280px) {
            margin-top: 30px;
        }
    }

    &__support-btn-container {
        padding-top: 15px;
    }

    &__support-btn {
        font-size: 16px;
        cursor: pointer;
        text-decoration: none;
    }

    &__text-icons {
        color: lightgray;
        height: 20px;
        margin-bottom: -2px;
        margin-right: 5px;

        &:hover {
            color: #dadada;
        }
    }

    &__logout-btn {
        cursor: 'pointer';
        font-size: '16px';
        padding-top: '15px';
    }
}

.gray-text {
    color: #61697d;
}
