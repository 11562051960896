@import '../../scss/variables/colors';
@import '../../scss/general/general';

.can-landing {
    position: relative;
    &__services-section {
        background-color: $main-yellow;
        padding: 100px 40px;

        & h1 {
            text-align: center;

            @media screen and (min-width: $breakpoint-sm) {
                text-align: left;
            }
        }

        & .hero-image-mask {
            margin: 0 auto;
            width: 300px;

            @media screen and (min-width: $breakpoint-sm) {
                width: 80%;
            }

            & img {
                width: 100%;
            }
        }
    }

    &__support-section {
        padding: 65px 0;

        & .support-wrapper {
            max-width: 80%;
            margin: 0 auto 50px;
            width: 100%;

            & img {
                width: 100%;
            }
        }

        & .support-top-wrapper {
            margin-bottom: 40px;
        }

        & .support-top-heading {
            margin: 0;
            text-align: center;
            font-size: 25px;
            line-height: 1.2;

            @media screen and (min-width: $breakpoint-sm) {
                font-size: 40px;
            }
        }
    }

    &__shop-local-section {
        padding: 80px 0;

        & h1 {
            margin-bottom: 50px;
        }
    }

    &__hero-section {
        background-color: $main-yellow;
    }

    & .paragraph {
        width: 80%;
        margin: 0 auto;
        font-size: 16px;
        font-weight: 700;
        text-align: center;

        @media screen and (min-width: $breakpoint-sm) {
            font-size: 25px;
            width: 60%;
        }
    }
}
