.user-info-part-input {
    background: white;
    border: 1px solid #c2c8d6;
    box-sizing: border-box;
    height: 50px;
    box-shadow: inset 0px 2px 2px rgba(108, 131, 145, 0.19);
    border-radius: 2px;
    margin-top: 8px;
    width: 100%;
    font-size: 16px;
    padding: 16px;

    &:focus {
        box-shadow: 0 0 3px steelblue;
        outline-width: 0px;
    }
    @media (max-width: 800px) {
        width: 100%;
    }
}

.form-width {
    width: 100%;
}
