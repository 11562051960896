@import '../../scss/general/general';

.main-layout {
    display: flex;

    &__drawer {
        background: white;
        color: #222;
        margin-left: 0;
        max-width: 230px;
        min-width: 230px;
        padding-top: 30px;
        transition: all 0.3s;

        @media (min-width: $breakpoint-sm) {
            margin-left: -230px;
        }

        &.active {
            margin-left: -230px;

            @media (min-width: $breakpoint-sm) {
                margin-left: 0;
            }
        }
    }

    &__content {
        -moz-box-shadow: inset 0 -7px 7px #d7d7d7;
        -webkit-box-shadow: inset 0 -7px 7px #d7d7d7;
        background-color: #fcfaf8;
        box-shadow: inset 0 -7px 7px #d7d7d7;
        min-height: 100vh;
        padding: 10px 20px 20px 20px;
        transition: all 0.3s;
    }

    & .form-group {
        margin-bottom: 16px;

        & p.info-text {
            color: #61697d;
            font-size: 12px;
        }
    }

    & .react-datepicker-wrapper {
        width: 100%;

        & .datepicker-selection {
            position: relative;

            & > span {
                position: absolute;
                right: 0;
                top: 0;
                background-color: white;
                height: 100%;
                display: block;
                width: 34px;
                -moz-box-shadow: 0 0 7px #d7d7d7;
                -webkit-box-shadow: 0 0 7px #d7d7d7;
                color: #0f0f0f;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    & ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    & h4 {
        font-family: 'Circular', sans-serif;
        font-weight: 500;
        font-size: 19px;
        margin: 0 0 16px;
    }

    .dashboard-btn {
        border-radius: 12px;
        padding: 5px 22px;
        border: 2px solid;
        color: #4051b6;
        font-size: 10px;
        font-weight: 500;
        font-family: 'Avenir', sans-serif;
        text-align: center;

        @media (min-width: $breakpoint-sm) {
            font-size: 13px;
        }

        &.Mui-disabled {
            color: rgba(0, 0, 0, 0.26);
        }

        &.filled {
            background-color: #4051b6;
            border-color: #4051b6;
            color: white;
        }

        &__icon {
            font-size: 1.2rem;
            margin-right: 13px;
        }
    }
}
