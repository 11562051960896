.edit-user {
    background: #fff;
    width: 620px;
    padding: 24px 32px 24px 32px;

    @media (max-width: 800px) {
        width: auto;
    }

    &__title {
        font-size: 24px;
        font-weight: 800;
    }

    &__close-icon {
        cursor: pointer;
    }

    &__user-info-container {
        &.MuiGrid-item {
            margin-top: 50px;
            @media (max-width: 800px) {
                margin-top: 20px;
            }
        }
    }

    &__user-image-panel {
        width: 200px;
    }

    &__user-info-panel {
        width: 400px;
        @media (max-width: 800px) {
            width: 100%;
            margin-top: 15px;
        }
    }

    &__user-image {
        width: 200px;
        height: 200px;
        margin-top: 8px;
        margin-bottom: 16px;
    }

    &__user-info-part {
        &.MuiGrid-item {
            margin-bottom: 24px;
        }
    }

    &__user-info-part-input {
        background: #fff;
        border: 1px solid #c2c8d6;
        box-sizing: border-box;
        height: 50px;
        box-shadow: inset 0px 2px 2px rgba(108, 131, 145, 0.19);
        border-radius: 2px;
        margin-top: 8px;
        width: 400px;
        font-size: 16px;
        padding: 16px;

        &:focus {
            box-shadow: 0 0 3px steelblue;
            outline: none;
        }

        @media (max-width: 800px) {
            width: 100%;
        }
    }

    &__user-info-part-radio {
        margin-top: 8px;
    }

    &__change-password-button {
        &.MuiButtonBase-root {
            margin-bottom: 24px;
        }
    }

    &__phone-input-parent {
        border: 1px solid #c2c8d6;
        box-sizing: border-box;
        box-shadow: inset 0px 2px 2px rgba(108, 131, 145, 0.19);
        height: 50px;
        padding-left: 20px;
        margin-top: 8px;

        &:focus-within {
            box-shadow: 0 0 3px steelblue;
        }
    }

    &__phone-input-child {
        border: none;
        box-shadow: none;
        margin-top: 0px;
        height: 0px;
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    &__change-password-paper {
        background: #fff;
        padding: 24px 32px 24px 32px;
    }

    &__change-password-main-container {
        &.MuiGrid-item {
            margin-top: 32px;
        }
    }

    &__info-message {
        &.MuiGrid-item {
            margin-bottom: 46px;
            margin-top: 32px;
            text-align: center;
            margin-right: auto;
            margin-left: auto;
            max-width: 400px;
        }
    }

    &__change-password-error-message {
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        max-width: 400px;
        color: #f46f6f;
    }

    &__input-text {
        float: left;
    }

    &__forgot-password-link {
        float: right;
    }

    &__save-button {
        &.MuiButtonBase-root {
            margin-left: 10px;
        }
    }
}

.gray-text {
    color: #61697d;
}
